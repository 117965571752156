import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UploadStatus {
  loading: boolean;
  complete: boolean;
}

export interface LiabilityOption {
  value: string;
  label: string;
}

interface CategoryOption {
  value: string;
  label: string;
}

export interface DocumentData {
  title: string;
  category: CategoryOption | null;
  liability: LiabilityOption | null;
  description: string;
  fileName: string | null;
  fileSize: number | null;
  fileType: string | null;
}

interface DocumentLibraryState {
  isUploadModalOpen: boolean;
  uploadProgress: number;
  uploadStatus: UploadStatus;
  documentData: DocumentData;
  uploading: boolean;
  fileTypeError: boolean;
  fileTypeErrorMsg: string;
}

export const getInitialState = (): DocumentLibraryState => ({
  isUploadModalOpen: false,
  uploadProgress: 0,
  uploadStatus: { loading: false, complete: false },
  uploading: false,
  fileTypeError: false,
  fileTypeErrorMsg: '',
  documentData: {
    title: '',
    category: null,
    liability: null,
    description: '',
    fileName: null,
    fileSize: null,
    fileType: null
  }
});

export const documentLibrarySlice = createSlice({
  name: 'documentLibrary',
  initialState: getInitialState(),
  reducers: {
    toggleUploadModal: (state) => {
      state.isUploadModalOpen = !state.isUploadModalOpen;
    },
    setUploadProgress: (state, action: PayloadAction<number>) => {
      state.uploadProgress = action.payload;
    },
    setUploadStatus: (state, action: PayloadAction<UploadStatus>) => {
      state.uploadStatus = action.payload;
    },
    setFileTypeError: (state, action: PayloadAction<boolean>) => {
      state.fileTypeError = action.payload;
    },
    setFileTypeErrorMsg: (state, action: PayloadAction<string>) => {
      state.fileTypeErrorMsg = action.payload;
    },
    resetSelectedFile: (state) => {
      state.uploadProgress = 0;
      state.uploadStatus = { loading: false, complete: false };
      state.documentData.fileName = '';
      state.documentData.fileSize = null;
      state.fileTypeError = false;
      state.fileTypeErrorMsg = '';
    },
    setFileData: (state, { payload: { fileName, fileSize, fileType } }) => {
      state.documentData.fileName = fileName;
      state.documentData.fileSize = fileSize;
      state.documentData.fileType = fileType;
    },
    setUploading: (state, action: PayloadAction<boolean>) => {
      state.uploading = action.payload;
    },
    updateState: (state, { payload }) => {
      Object.assign(state.documentData, payload.documentData);
    },
    resetState: () => getInitialState()
  }
});

export const {
  toggleUploadModal,
  setFileData,
  setUploadProgress,
  setUploadStatus,
  resetSelectedFile,
  updateState,
  resetState,
  setUploading,
  setFileTypeError,
  setFileTypeErrorMsg
} = documentLibrarySlice.actions;

export const updateUploadProgress = createAsyncThunk(
  'documentLibrary/updateUploadProgress',
  async (_, { dispatch }) => {
    let progress = 0;
    dispatch(setUploadStatus({ loading: true, complete: false }));
    return new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        progress += 10;
        dispatch(setUploadProgress(progress));
        if (progress >= 100) {
          clearInterval(interval);
          dispatch(setUploadStatus({ loading: false, complete: true }));
          resolve();
        }
      }, 100);
    });
  }
);

export default documentLibrarySlice.reducer;
