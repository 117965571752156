import colors from '@brands/themes/clg/colors';

const typography = {
  fontFamily: 'Inter',
  h2: {
    fontSize: '30px',
    fontWeight: 700,
    color: colors.darkGrey
  },
  h3: {
    fontSize: '16px',
    fontWeight: 600,
    color: colors.black
  },
  body1: {
    fontSize: '14px',
    fontWeight: 400,
    color: colors.grey
  },
  caption: {
    fontSize: '12px',
    fontWeight: 600,
    color: colors.grey
  },
  button: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '14px',
    color: colors.white
  }
};

export default typography;
