import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '@api/baseQuery';

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery,
  endpoints: (builder) => ({
    getPayments: builder.query<any, void>({
      query: () => '/enrollment/payments'
    })
  })
});

export const { useGetPaymentsQuery } = paymentsApi;
