import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useGetCurrentSessionQuery } from '@api/auth';
import { useAppDispatch, useAppSelector } from '@hooks/rtk';
import { selectSessionData, setPasswordChanged } from '@pages/Home/CreatePassword/createPasswordSlice';
import { selectIsLoggedIn } from '@pages/Login/loginSlice';

const ProtectedRoute: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data: sessionData, isFetching } = useGetCurrentSessionQuery();
  const accessToken = sessionData?.accessToken;
  const isForceChangePassword = useAppSelector(selectSessionData)?.isForceChangePassword;
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  useEffect(() => {
    if (!isFetching && accessToken)
    dispatch(setPasswordChanged(true));
  }, [isFetching, dispatch, accessToken]);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return isLoggedIn || accessToken || isForceChangePassword ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
