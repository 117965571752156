import { createApi } from '@reduxjs/toolkit/query/react';
import { PaymentDetailsResponse } from '@api/paymentDetails/types';
import baseQuery from '@api/baseQuery';

export const paymentDetailsApi = createApi({
  reducerPath: 'paymentDetailsApi',
  baseQuery,
  endpoints: (builder) => ({
    getPaymentDetails: builder.query<PaymentDetailsResponse, void>({
      query: () => '/enrollment/paymentdetails'
    })
  })
});

export const { useGetPaymentDetailsQuery } = paymentDetailsApi;
