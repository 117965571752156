import { createApi } from '@reduxjs/toolkit/query/react';
import { DocumentCategory } from '@api/documentCategories/types';
import baseQuery from '@api/baseQuery';

export const documentCategoriesApi = createApi({
  reducerPath: 'documentCategoriesApi',
  baseQuery,
  endpoints: (builder) => ({
    getDocumentCategories: builder.query<DocumentCategory[], void>({
      query: () => ({
        url: '/systemSettings/document/categories',
        method: 'GET'
      })
    })
  })
});

export const { useGetDocumentCategoriesQuery } = documentCategoriesApi;
