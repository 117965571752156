import { createApi } from '@reduxjs/toolkit/query/react';
import {
  DocumentResponse,
  DocumentsResponse,
  UploadDocumentMetadataRequest
} from '@api/documents/types';
import baseQuery from '@api/baseQuery';

export const documentsApi = createApi({
  reducerPath: 'documentsApi',
  baseQuery,
  endpoints: (builder) => ({
    getDocuments: builder.query<DocumentsResponse, void>({
      query: () => '/document/documents'
    }),
    getDocument: builder.query<DocumentResponse, { id: string }>({
      query: ({ id }) => ({
        url: `/document/${id}`,
        method: 'GET'
      })
    }),
    uploadDocumentMetadata: builder.mutation<
      URL,
      UploadDocumentMetadataRequest
    >({
      query: (body) => ({
        url: '/document/save',
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useGetDocumentsQuery,
  useLazyGetDocumentQuery,
  useUploadDocumentMetadataMutation,
  useLazyGetDocumentsQuery
} = documentsApi;
