const colors = {
  black: '#000000',
  darkGrey: '#515151',
  disabled: '#DDE7EE',
  disabledBg: 'rgba(2, 120, 94, 0.50)',
  errorMain: '#EF3C3C',
  grey100: '#F7F7F7',
  grey200: '#EAECF0',
  grey400: '#667084',
  grey50: '#EFF3F8',
  grey600: '#555E68',
  grey800: '#505050',
  grey: '#667085',
  hoverBg: '#006C54',
  infoMain: '#02785E',
  primaryMain: '#02785E',
  secondaryMain: '#40B591',
  successMain: '#71D461',
  textPrimary: '#16402B',
  textSecondary: '#505050',
  warningMain: '#FFB00F',
  white: '#FFFFFF',
  focusColor: '#3B82F6',
  inputBorderColor: '#CED4DA',
  inputBorderColorHover: '#6C757D',
  labelColor: '#C0C0C0',
  listItemBgHover: '#D1EDDB',
  debtsColor: '#BEC0C2',
  chartLegendsFill: '#F2F4F7',
  inputLabelTextColor: '#6C757D',
  debtsColorMain: '#40B591',
  debtsColorSecondary: '#F79009',
  debtsGreenStatusColor: '#37BF22',
  debtsGreenStatusBg: 'rgba(55, 191, 34, 0.12)',
  debtsBlueStatusColor: '#2E90FA',
  debtsBlueStatusBg: 'rgba(46, 144, 250, 0.12)',
  debtsYellowStatusColor: '#EBBB10',
  debtsYellowStatusBg: 'rgba(235, 187, 16, 0.12)',
  debtsOrangeStatusColor: '#F79009',
  debtsOrangeStatusBg: 'rgba(247, 144, 9, 0.12)',
  debtsRedStatusColor: '#F04438',
  debtsRedStatusBg: 'rgba(240, 68, 56, 0.12)',
  primaryChipColor: '#18AB00',
  primaryChipBg: 'rgba(24, 171, 0, 0.1)',
  avatarBg: 'rgba(64, 181, 145, 0.1)',
  mainBg: '#f8f8f8',
  systemBlue10: 'rgba(59, 130, 246, 0.1)',
  systemRed10: 'rgba(239, 60, 60, 0.1)',
  systemSunRays10: 'rgba(247, 144, 9, 0.1)',
  disabledButtonBg: 'rgba(2, 120, 94, 0.5)',
  updateButtonHoverBg: 'rgba(2, 120, 94, 0.12)',
  backButtonSvgColor: '#9FA6AD',
  backButtonColor: 'rgba(22, 64, 43, 0.5)',
  autocompleteOptionFocusBg: '#F6F9FC',
  autocompleteOptionFocusColor: '#32383E',
  chartBarHoverColor: '#34ab26',
  fileDetailsColor: '#495057',
  uploadAreaDescription: '#989C9F'
};

export default colors;
