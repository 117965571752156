import {
  Action,
  combineReducers,
  configureStore,
  createAction
} from '@reduxjs/toolkit';
import createPasswordReducer from '@components/pages/Home/CreatePassword/createPasswordSlice';
import changePasswordReducer from '@components/pages/MyAccount/ChangePassword/changePasswordSlice';
import loginReducer from '@components/pages/Login/loginSlice';
import resetReducer from '@components/pages/ResetPassword/resetPasswordSlice';
import paymentMethodReducer from '@pages/PaymentMethod/paymentMethodSlice';
import snackBarReducer from '@common/CustomSnackBar/snackbarSlice';
import documentLibraryReducer from '@pages/DocumentLibrary/documentLibrarySlice';
import {
  authApi,
  bankAccountInfoApi,
  companyApi,
  creditCardInfoApi,
  documentCategoriesApi,
  documentsApi,
  liabilitiesInfoApi,
  liabilityBalancesApi,
  paymentDetailsApi,
  paymentsApi,
  profileInfoApi
} from '@api/index';

export const resetAppState = createAction('reset/appState');

const combinedReducer = combineReducers({
  login: loginReducer,
  createPassword: createPasswordReducer,
  changePassword: changePasswordReducer,
  reset: resetReducer,
  paymentMethod: paymentMethodReducer,
  snackBar: snackBarReducer,
  documentLibrary: documentLibraryReducer,
  [authApi.reducerPath]: authApi.reducer,
  [liabilityBalancesApi.reducerPath]: liabilityBalancesApi.reducer,
  [paymentDetailsApi.reducerPath]: paymentDetailsApi.reducer,
  [liabilitiesInfoApi.reducerPath]: liabilitiesInfoApi.reducer,
  [profileInfoApi.reducerPath]: profileInfoApi.reducer,
  [paymentsApi.reducerPath]: paymentsApi.reducer,
  [bankAccountInfoApi.reducerPath]: bankAccountInfoApi.reducer,
  [creditCardInfoApi.reducerPath]: creditCardInfoApi.reducer,
  [documentsApi.reducerPath]: documentsApi.reducer,
  [documentCategoriesApi.reducerPath]: documentCategoriesApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer
});

const rootReducer = (state: any | undefined, action: Action) => {
  if (resetAppState.match(action)) {
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      liabilityBalancesApi.middleware,
      paymentDetailsApi.middleware,
      liabilitiesInfoApi.middleware,
      profileInfoApi.middleware,
      paymentsApi.middleware,
      bankAccountInfoApi.middleware,
      creditCardInfoApi.middleware,
      documentsApi.middleware,
      documentCategoriesApi.middleware,
      companyApi.middleware
    )
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
