import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getCurrentSession } from '@api/auth/cognitoService';

const baseQuery = fetchBaseQuery({
  baseUrl: encodeURI(`${process.env.REACT_APP_API_BASE_URL}`),
  prepareHeaders: async (headers) => {
    const sessionData = await getCurrentSession();
    const token = sessionData.idToken;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
  responseHandler: async (response) => {
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    }
    return response.text();
  }
});

export default baseQuery;
