import colors from '@brands/themes/clg/colors';

const palette = {
  primary: {
    main: colors.primaryMain,
    contrastText: colors.white
  },
  secondary: {
    main: colors.secondaryMain
  },
  error: {
    main: colors.errorMain
  },
  warning: {
    main: colors.warningMain
  },
  info: {
    main: colors.infoMain
  },
  success: {
    main: colors.successMain
  },
  common: {
    white: colors.white,
    black: colors.black
  },
  grey: {
    50: colors.grey50,
    100: colors.grey100,
    200: colors.grey200,
    400: colors.grey400,
    600: colors.grey600,
    800: colors.grey800
  },
  background: {
    default: colors.grey100,
    paper: colors.white
  },
  text: {
    primary: colors.textPrimary,
    secondary: colors.textSecondary,
    disabled: colors.disabled
  },
  action: {
    disabledBackground: colors.disabled,
    disabled: colors.disabled
  }
};

export default palette;
