import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  changePassword,
  completeNewPasswordChallenge,
  confirmForgotPassword,
  forgotPassword,
  getCurrentSession,
  SessionData,
  signIn,
  signOut
} from '@api/auth/cognitoService';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (builder) => ({
    signIn: builder.mutation<SessionData, { email: string; password: string }>({
      queryFn: async (arg) => {
        try {
          const session = await signIn(arg.email, arg.password);
          return { data: session };
        } catch (error: any) {
          return { error: { status: 'CUSTOM_ERROR', error: error.message } };
        }
      }
    }),
    getCurrentSession: builder.query<SessionData, void>({
      queryFn: async () => {
        try {
          const session = await getCurrentSession();
          return { data: session };
        } catch (error: any) {
          return { error: { status: 'CUSTOM_ERROR', error: error.message } };
        }
      }
    }),
    completeNewPasswordChallenge: builder.mutation<
      SessionData,
      { newPassword: string }
    >({
      queryFn: async (arg) => {
        try {
          const session = await completeNewPasswordChallenge(arg.newPassword);
          return { data: session };
        } catch (error: any) {
          return { error: { status: 'CUSTOM_ERROR', error: error.message } };
        }
      }
    }),
    changePassword: builder.mutation<
      { message: string },
      { oldPassword: string; newPassword: string }
    >({
      queryFn: async (arg) => {
        try {
          const message = await changePassword(
            arg.oldPassword,
            arg.newPassword
          );
          return { data: { message } };
        } catch (error: any) {
          return {
            error: {
              status: 'FETCH_ERROR',
              error:
                error || 'An unexpected error occurred during password change'
            }
          };
        }
      }
    }),
    signOut: builder.mutation<void, void>({
      queryFn: () => {
        signOut();
        return { data: undefined };
      }
    }),
    forgotPassword: builder.mutation<void, { email: string }>({
      queryFn: async (arg) => {
        try {
          await forgotPassword(arg.email);
          return { data: undefined };
        } catch (error: any) {
          return { error: { status: 'CUSTOM_ERROR', error: error.message } };
        }
      }
    }),
    confirmForgotPassword: builder.mutation<
      void,
      { code: string; newPassword: string }
    >({
      queryFn: async (arg) => {
        try {
          await confirmForgotPassword(arg.code, arg.newPassword);
          return { data: undefined };
        } catch (error: any) {
          return { error: { status: 'CUSTOM_ERROR', error: error.message } };
        }
      }
    })
  })
});

export const {
  useSignInMutation,
  useGetCurrentSessionQuery,
  useCompleteNewPasswordChallengeMutation,
  useChangePasswordMutation,
  useSignOutMutation,
  useForgotPasswordMutation,
  useConfirmForgotPasswordMutation
} = authApi;
