import { createApi } from '@reduxjs/toolkit/query/react';
import { LiabilityBalanceResponse } from '@api/liabilityBalances/types';
import baseQuery from '@api/baseQuery';

export const liabilityBalancesApi = createApi({
  reducerPath: 'liabilityBalancesApi',
  baseQuery,
  endpoints: (builder) => ({
    getLiabilityBalances: builder.query<LiabilityBalanceResponse, void>({
      query: () => '/liability/balances'
    })
  })
});

export const { useGetLiabilityBalancesQuery } = liabilityBalancesApi;
