import { createApi } from '@reduxjs/toolkit/query/react';
import {
  BankAccountInfoResponse,
  BankAccountProfileRequest
} from '@api/bankAccountInfo/types';
import baseQuery from '@api/baseQuery';

export const bankAccountInfoApi = createApi({
  reducerPath: 'bankAccountInfoApi',
  baseQuery,
  endpoints: (builder) => ({
    getBankAccountInfo: builder.query<BankAccountInfoResponse, void>({
      query: () => '/bankAccount/info'
    }),
    createBankAccountProfile: builder.mutation<void, BankAccountProfileRequest>(
      {
        query: (body) => ({
          url: '/bankAccount/profile',
          method: 'POST',
          body
        })
      }
    ),
    updateBankAccount: builder.mutation<
      void,
      { id: string; body: BankAccountProfileRequest }
    >({
      query: ({ id, body }) => ({
        url: `/bankAccount/${id}`,
        method: 'PUT',
        body
      })
    })
  })
});

export const {
  useGetBankAccountInfoQuery,
  useCreateBankAccountProfileMutation,
  useUpdateBankAccountMutation
} = bankAccountInfoApi;
