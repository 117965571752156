import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum PaymentMethod {
  Add = 'add',
  Update = 'update'
}

export interface PaymentMethodState {
  isOpen: boolean;
  activeStep: number;
  paymentType: string;
  bankAccountType: string;
  bankName: string;
  accountHolder: string;
  accountNumber: string;
  routingNumber: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  stateLabel: string;
  zipcode: string;
  cardNumber: string;
  expDate: string;
  cvv: string;
  cardHolderName: string;
  selectedPaymentMethodId: string;
  paymentMethod: PaymentMethod | '';
  shouldRefetch: boolean;
  existingPaymentTypes: string[];
  isConfirmationModalOpen: boolean;
}

export const initialState: PaymentMethodState = {
  isOpen: false,
  activeStep: 0,
  paymentType: '',
  bankAccountType: '',
  bankName: '',
  accountHolder: '',
  accountNumber: '',
  routingNumber: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  stateLabel: '',
  zipcode: '',
  cardNumber: '',
  expDate: '',
  cvv: '',
  cardHolderName: '',
  selectedPaymentMethodId: '',
  paymentMethod: '',
  shouldRefetch: false,
  existingPaymentTypes: [],
  isConfirmationModalOpen: false
};

export const paymentMethodSlice = createSlice({
  name: 'paymentMethod',
  initialState,
  reducers: {
    updateState: (
      state,
      action: PayloadAction<Partial<PaymentMethodState>>
    ) => ({
      ...state,
      ...action.payload
    }),

    updateExistingPaymentTypes: (state, action: PayloadAction<string[]>) => {
      state.existingPaymentTypes = action.payload;
    },

    openStepper: (
      state,
      action: PayloadAction<{ step?: number; paymentMethod?: PaymentMethod }>
    ) => {
      state.isOpen = true;
      state.activeStep = action.payload.step ?? 0;
      state.paymentMethod = action.payload.paymentMethod ?? PaymentMethod.Add;
    },
    closeStepper: (state) => {
      state.isOpen = false;
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },

    resetPaymentMethod: (state) => {
      const { existingPaymentTypes } = state;
      return { ...initialState, existingPaymentTypes };
    }
  }
});

export const {
  updateState,
  openStepper,
  closeStepper,
  setActiveStep,
  resetPaymentMethod,
  updateExistingPaymentTypes
} = paymentMethodSlice.actions;

export default paymentMethodSlice.reducer;
