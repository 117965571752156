import { createTheme } from '@mui/material/styles';
import clgLogo from '@brands/logos/CLG_logo.svg';
import sidebarLogo from '@brands/logos/Logo_CLG_Sidebar.svg';
import index from '@brands/themes/clg';
import colors from '@brands/themes/clg/colors';

interface BrandingConfig {
  logoImg: string;
  sidebarLogo: string;
  logoAlt: string;
  theme: ReturnType<typeof createTheme>;
  colors: any;
  favicon: string;
  containerLogoWidth: number;
  modalLogoWidth: number;
}

const brandingConfig: BrandingConfig = {
  logoImg: clgLogo,
  sidebarLogo,
  logoAlt: 'CLG Logo',
  containerLogoWidth: 400,
  modalLogoWidth: 188,
  theme: index,
  favicon: '/clg.ico',
  colors
};

export default brandingConfig;
