import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';

interface ChangePasswordFormState {
  isModalOpen: boolean;
  form: {
    currentPassword: string;
    newPassword: string;
    repeatNewPassword: string;
  };
  formErrors: {
    newPasswordError: string;
    repeatNewPasswordError: string;
    serverError: string;
  };
  hasPasswordChanged: boolean;
}

enum ChangePasswordActionTypes {
  ToggleModal = 'toggleModal',
  UpdateForm = 'updateForm',
  SetFormErrors = 'setFormErrors',
  SetPasswordChanged = 'setPasswordChanged'
}

const initialState: ChangePasswordFormState = {
  isModalOpen: false,
  form: {
    currentPassword: '',
    newPassword: '',
    repeatNewPassword: ''
  },
  formErrors: {
    newPasswordError: '',
    repeatNewPasswordError: '',
    serverError: ''
  },
  hasPasswordChanged: false
};

export const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {
    [ChangePasswordActionTypes.ToggleModal]: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isModalOpen = action.payload;
      if (!action.payload) {
        state.form = initialState.form;
        state.formErrors = initialState.formErrors;
      }
    },
    [ChangePasswordActionTypes.UpdateForm]: (
      state,
      action: PayloadAction<Partial<typeof initialState.form>>
    ) => {
      state.form = { ...state.form, ...action.payload };
    },
    [ChangePasswordActionTypes.SetFormErrors]: (
      state,
      action: PayloadAction<Partial<typeof initialState.formErrors>>
    ) => {
      state.formErrors = { ...state.formErrors, ...action.payload };
    },
    [ChangePasswordActionTypes.SetPasswordChanged]: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.hasPasswordChanged = action.payload;
    }
  }
});

export const { toggleModal, updateForm, setFormErrors, setPasswordChanged } =
  changePasswordSlice.actions;

export const selectChangePasswordState = (state: RootState) =>
  state.changePassword;

export default changePasswordSlice.reducer;
