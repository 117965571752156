import { createTheme } from '@mui/material/styles';
import palette from '@brands/themes/clg/palette';
import typography from '@brands/themes/clg/typography';
import components from '@brands/themes/clg/components';

const themeOptions = {
  palette,
  typography,
  shape: {
    borderRadius: 8
  },
  components
};

const index = createTheme(themeOptions);

export default index;
