import colors from '@brands/themes/clg/colors';

const components = {
  MuiAutocomplete: {
    styleOverrides: {
      paper: {
        marginTop: '8px'
      },
      listbox: {
        padding: 0,
        '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused, & .MuiAutocomplete-option[aria-selected="true"], .Mui-focused':
          {
            color: colors.white,
            backgroundColor: colors.infoMain
          },
        '& .MuiAutocomplete-option.Mui-focused': {
          backgroundColor: colors.autocompleteOptionFocusBg,
          color: colors.autocompleteOptionFocusColor
        }
      }
    }
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: colors.textPrimary
      },
      arrow: {
        color: colors.textPrimary
      }
    }
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: 1.42,
        marginLeft: 0,
        marginRight: 0,
        marginTop: '8px'
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '&.Mui-focused': {
          '& > .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.focusColor
          }
        }
      },
      notchedOutline: {
        borderColor: colors.inputBorderColor,
        '&:hover': {
          borderColor: colors.inputBorderColorHover
        }
      }
    }
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: colors.labelColor,
        '&.Mui-focused': {
          color: colors.focusColor
        }
      }
    }
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none' as any,
        borderRadius: '6px',
        '&:hover': {
          backgroundColor: colors.hoverBg
        },
        '&:enabled': {
          backgroundColor: colors.primaryMain
        },
        '&:disabled': {
          backgroundColor: colors.disabledBg,
          color: colors.white
        }
      },
      text: {
        '&:hover': {
          backgroundColor: 'transparent'
        },
        '&:enabled': {
          backgroundColor: 'transparent'
        }
      }
    }
  }
};

export default components;
