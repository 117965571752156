import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';

interface ResetState {
  email: string;
  code: string;
  newPassword: string;
  repeatNewPassword: string;
  resetError: string;
  codeError: string;
  newPasswordError: string;
  repeatNewPasswordError: string;
}

const initialState: ResetState = {
  email: '',
  code: '',
  newPassword: '',
  repeatNewPassword: '',
  resetError: '',
  codeError: '',
  newPasswordError: '',
  repeatNewPasswordError: ''
};

export const resetSlice = createSlice({
  name: 'reset',
  initialState,
  reducers: {
    setResetFormData: (state, action: PayloadAction<Partial<ResetState>>) => ({
      ...state,
      ...action.payload
    }),
    resetForm: () => initialState
  }
});

export const { setResetFormData, resetForm } = resetSlice.actions;

export const selectResetForm = (state: RootState) => state.reset;

export default resetSlice.reducer;
