import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '@api/baseQuery';

import { CompanyInfoResponse } from './types';

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery,
  endpoints: (builder) => ({
    getCompanyInfo: builder.query<CompanyInfoResponse, void>({
      query: () => '/company/info'
    })
  })
});

export const { useGetCompanyInfoQuery } = companyApi;
