import React, { lazy, Suspense, useEffect } from 'react';
import brandingConfig from '@brands/branding.config';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';

const Debts = lazy(() => import('@pages/Debts'));
const Help = lazy(() => import('@pages/Help'));
const Home = lazy(() => import('@pages/Home'));
const Login = lazy(() => import('@pages/Login'));
const MyAccount = lazy(() => import('@pages/MyAccount'));
const NotFound = lazy(() => import('@pages/NotFound'));
const Reset = lazy(() => import('@pages/ResetPassword'));
const ConfirmResetPassword = lazy(
  () => import('@pages/ResetPassword/ConfirmResetPassword')
);
const PaymentMethod = lazy(() => import('@pages/PaymentMethod'));
const DocumentLibrary = lazy(() => import('@pages/DocumentLibrary'));
const Payments = lazy(() => import('@pages/Payments'));

const App: React.FC = () => {
  useEffect(() => {
    const link = document.querySelector("link[rel~='icon']");
    link?.setAttribute('href', brandingConfig.favicon);
  }, []);

  return (
    <Router>
      <Suspense>
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/help" element={<Help />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/debts" element={<Debts />} />
            <Route path="/payment-methods" element={<PaymentMethod />} />
            <Route path="/document-library" element={<DocumentLibrary />} />
            <Route path="/payments" element={<Payments />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<Reset />} />
          <Route
            path="/confirm-reset-password"
            element={<ConfirmResetPassword />}
          />
          <Route path="*" element={<NotFound />} />
          <Route path="/health" element={<p>Health</p>} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
