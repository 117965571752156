import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CreditCardInfoResponse,
  CreditCardProfileRequest
} from '@api/creditCardInfo/types';
import baseQuery from '@api/baseQuery';

export const creditCardInfoApi = createApi({
  reducerPath: 'creditCardInfoApi',
  baseQuery,
  endpoints: (builder) => ({
    getCreditCardInfo: builder.query<CreditCardInfoResponse, void>({
      query: () => '/creditCard/info'
    }),
    createCreditCardProfile: builder.mutation<void, CreditCardProfileRequest>({
      query: (body) => ({
        url: `creditCard/profile`,
        method: 'POST',
        body
      })
    }),
    updateCreditCard: builder.mutation<
      void,
      { id: string; body: CreditCardProfileRequest }
    >({
      query: ({ id, body }) => ({
        url: `creditCard/${id}`,
        method: 'PUT',
        body
      })
    })
  })
});

export const {
  useGetCreditCardInfoQuery,
  useCreateCreditCardProfileMutation,
  useUpdateCreditCardMutation
} = creditCardInfoApi;
