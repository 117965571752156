import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';
import { SessionData } from '@api/auth/cognitoService';

interface CreatePasswordState {
  isCreatePasswordModalOpen: boolean;
  hasPasswordChanged: boolean;
  sessionData?: SessionData;
  isCreatePasswordSuccessful: boolean;
}

enum CreatePasswordActions {
  OpenModal = 'openCreatePasswordModal',
  CloseModal = 'closeCreatePasswordModal',
  SetPasswordChanged = 'setPasswordChanged',
  SetSessionData = 'setSessionData',
  OpenSuccessfulModal = 'openSuccessfulModal',
  CloseSuccessfulModal = 'closeSuccessfulModal'
}

const initialState: CreatePasswordState = {
  isCreatePasswordModalOpen: false,
  hasPasswordChanged: false,
  isCreatePasswordSuccessful: false
};

export const createPasswordSlice = createSlice({
  name: 'createPassword',
  initialState,
  reducers: {
    [CreatePasswordActions.OpenModal]: (state) => {
      state.isCreatePasswordModalOpen = true;
    },
    [CreatePasswordActions.CloseModal]: (state) => {
      state.isCreatePasswordModalOpen = false;
    },
    [CreatePasswordActions.SetPasswordChanged]: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.hasPasswordChanged = action.payload;
    },
    [CreatePasswordActions.SetSessionData]: (
      state,
      action: PayloadAction<SessionData>
    ) => {
      state.sessionData = action.payload;
    },
    [CreatePasswordActions.OpenSuccessfulModal]: (state) => {
      state.isCreatePasswordSuccessful = true;
    },
    [CreatePasswordActions.CloseSuccessfulModal]: (state) => {
      state.isCreatePasswordSuccessful = false;
    }
  }
});

export const {
  openCreatePasswordModal,
  closeCreatePasswordModal,
  setPasswordChanged,
  setSessionData,
  closeSuccessfulModal,
  openSuccessfulModal
} = createPasswordSlice.actions;

export const selectIsCreatePasswordModalOpen = (state: RootState) =>
  state.createPassword.isCreatePasswordModalOpen;
export const selectHasPasswordChanged = (state: RootState) =>
  state.createPassword.hasPasswordChanged;
export const selectSessionData = (state: RootState) =>
  state.createPassword.sessionData;

export default createPasswordSlice.reducer;
