import { createApi } from '@reduxjs/toolkit/query/react';
import { LiabilitiesInfoResponse } from '@api/liabilitiesInfo/types';
import baseQuery from '@api/baseQuery';

export const liabilitiesInfoApi = createApi({
  reducerPath: 'liabilitiesInfoApi',
  baseQuery,
  endpoints: (builder) => ({
    getLiabilitiesInfo: builder.query<LiabilitiesInfoResponse, void>({
      query: () => '/liability/liabilities/info'
    })
  })
});

export const { useGetLiabilitiesInfoQuery } = liabilitiesInfoApi;
