import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import brandingConfig from '@brands/branding.config';

const { colors } = brandingConfig;
export interface SnackbarState {
  isSnackBarOpen: boolean;
  message: string;
  title: string;
  color: string;
}

const initialState: SnackbarState = {
  isSnackBarOpen: false,
  message: '',
  title: '',
  color: ''
};

export const snackbarSlice = createSlice({
  name: 'snackBar',
  initialState,
  reducers: {
    showSnackbar: (
      state,
      action: PayloadAction<{ message: string; title: string; color: string }>
    ) => {
      state.isSnackBarOpen = true;
      state.message = action.payload.message;
      state.title = action.payload.title;
      state.color = action.payload.color;
    },
    hideSnackbar: (state) => {
      state.isSnackBarOpen = false;
      state.message = '';
      state.title = '';
      state.color = '';
    },
    showErrorSnackbar: (state, action: PayloadAction<string>) => {
      state.isSnackBarOpen = true;
      state.message = action.payload;
      state.title = 'Error';
      state.color = colors.errorMain;
    },
    showSuccessSnackbar: (state, action: PayloadAction<string>) => {
      state.isSnackBarOpen = true;
      state.message = action.payload;
      state.title = 'Success';
      state.color = colors.primaryChipColor;
    }
  }
});

export const {
  showSnackbar,
  hideSnackbar,
  showErrorSnackbar,
  showSuccessSnackbar
} = snackbarSlice.actions;

export default snackbarSlice.reducer;
