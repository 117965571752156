import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface LoginState {
  email: string;
  password: string;
  isLoggedIn: boolean;
}

const initialState: LoginState = {
  email: '',
  password: '',
  isLoggedIn: false
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setLoggedInStatus: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    }
  }
});

export const { setEmail, setPassword, setLoggedInStatus } = loginSlice.actions;

export const selectLoginInfo = (state: RootState) => state.login;
export const selectIsLoggedIn = (state: RootState) => state.login.isLoggedIn;

export default loginSlice.reducer;
