import { createApi } from '@reduxjs/toolkit/query/react';
import { ProfileInfoResponse } from '@api/profileInfo/types';
import baseQuery from '@api/baseQuery';

export const profileInfoApi = createApi({
  reducerPath: 'profileInfoApi',
  baseQuery,
  endpoints: (builder) => ({
    getProfileInfo: builder.query<ProfileInfoResponse, void>({
      query: () => '/profile/info'
    })
  })
});

export const { useGetProfileInfoQuery } = profileInfoApi;
